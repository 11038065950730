<template>
  <div class="header">
    <div class="logo-top">
      <img src="@/assets/logo-full.png" alt="" class="logo" />
      <div class="search-input">
        <input
          v-model="searchKeyword"
          type="text"
          class="input-text"
          placeholder="请输入关键字"
          @keypress.enter="handleSearch('search')"
        />
        <button class="search-btn" @click="handleSearch('search')">搜索</button>
      </div>
    </div>
    <div class="inner">
      <div class="left font">
        <template v-for="item in menus">
          <a
            target="_blank"
            rel="noopener noreferrer"
            class="button"
            :class="{ active: $route.path.includes(`/web/${item.id}`) }"
            :key="item.id"
            :href="item.href || `/web/${item.id}`"
            >{{ item.label }}</a
          >
        </template>
      </div>
      <div class="right">
        <div v-if="userInfo.id" class="user-info">
          <div class="avatar">
            <img :src="userInfo.avatar || userInfo.avatarImg" alt="" />
          </div>
          <div class="info">
            <div class="info-top">
              {{ userInfo.mobile }}
              <img
                src="@/assets/images/header/logout.png"
                alt=""
                @click="logout"
              />
            </div>
            <div class="info-bottom">
              <router-link to="/web/user/center">个人中心</router-link>
            </div>
          </div>
        </div>
        <template v-else>
          <span class="meta-info">
            <span @click="bus.emit('login')">登录</span>/<span
              @click="bus.emit('register')"
              >注册</span
            >
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import avatar from "@/assets/images/header/avatar.png";

export default {
  name: "Header",
  props: {
    msg: String
  },
  data() {
    return {
      searchKeyword: "",
      menus: [
        {
          label: "首页",
          id: "home"
        },
        {
          label: "寻根查谱",
          id: "search"
        },
        {
          label: "曾子学堂",
          id: "lecture"
          // href: "http://mmxp5000.com/web/school/list"
        },
        {
          label: "寻根动态",
          id: "events"
          // href: "http://mmxp5000.com/web/publish/list"
        },
        {
          label: "关于我们",
          id: "about"
        },
        {
          label: "编修族谱",
          id: "bianxiu",
          href: "http://xp.wczstp.com"
        }
      ]
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  created() {
    // this.fetchUserInfo();
  },
  methods: {
    ...mapActions(["fetchUserInfo", "clearUserInfo"]),
    logout() {
      this.$confirm("确认退出登陆吗", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.clearUserInfo();
          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch(() => {});
    },
    handleSearch() {
      // if (type === "search" && !this.searchKeyword.trim()) return;
      this.$router.push({
        path: "/web/article",
        query: { search_text: this.searchKeyword }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  align-items: center;
  // background-color: #fbe1c3;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  // height: 70px;
  overflow: hidden;
  position: relative;
  width: 100%;

  .logo-top {
    position: relative;
    height: 145px;
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      height: 100px;
      object-fit: contain;
      // width: 272px;
    }

    .search-input {
      width: 234px;
      height: 28px;
      border: 1px solid #000000;
      font-size: 12px;
      box-sizing: border-box;

      .input-text {
        color: #666666;
        height: 23px;
        border: none;
        width: 178px;
      }

      .search-btn {
        width: 50px;
        height: 26px;
        background-color: #cc313e;
        color: #ffffff;
        border: none;
      }
    }
  }

  .inner {
    position: relative;
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    background-color: #ca0a15;

    .left {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .button {
        // color: #CA0A15;
        font-size: 17px;
        letter-spacing: 0;
        line-height: 24px;
        width: 140px;
        // padding: 0 30px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        box-sizing: border-box;
        color: #ffffff;
        font-weight: bold;

        &.active {
          color: #ffffff;
          background-color: #b3050b;
          // border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 54px;
          // padding: 0 27px;
        }
      }
      // .button-wrap {
      //   align-items: center;
      //   background-color: #CA0A15;
      //   border-radius: 20px;
      //   box-sizing: border-box;
      //   display: flex;
      //   flex-direction: row;
      //   height: 40px;
      //   margin-right: 18px;
      //   overflow: hidden;
      //   padding-left: 27px;
      //   padding-right: 27px;
      //   .button {
      //     color: #ffffff;
      //     font-size: 24px;
      //     letter-spacing: 0;
      //     line-height: 24px;
      //     margin-right: 0;
      //     text-decoration: none;
      //   }
      // }
    }

    .right {
      align-items: center;
      display: flex;
      flex-direction: row;
      float: right;
      height: 17px;
      margin-right: 20px;
      color: #fff;

      .user-info {
        display: flex;
        align-items: center;

        .avatar {
          // border: 2px solid #a89884;
          border-radius: 50%;
          overflow: hidden;
          height: 40px;
          width: 40px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .info {
          display: flex;
          flex-flow: column nowrap;
          // color: #CA0A15;
          margin-left: 9px;

          .info-top {
            font-size: 14px;
            font-weight: bold;

            img {
              object-fit: contain;
              cursor: pointer;
            }
          }

          .info-bottom {
            margin-top: 7px;
            font-size: 12px;

            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }

      .meta-info {
        -webkit-line-clamp: 2;
        color: #fff;
        font-size: 14px;
        height: 16px;
        letter-spacing: 0;
        line-height: 14px;
        // margin-right: 9px;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        width: 102px;

        span {
          cursor: pointer;
        }

        a {
          color: #CA0A15;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
