import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mitt from "@/utils/mitt";
import "normalize.css";
import "./global.scss";
import { Message, MessageBox, Loading, Notification } from "element-ui";
import { Dialog } from 'vant'
import dayjs from "dayjs";

Vue.config.productionTip = false;
Vue.prototype.bus = mitt;
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$format = function(time, format = "YYYY-MM-DD") {
  return dayjs(time).format(format);
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
