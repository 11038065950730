import { Message } from "element-ui";
import { Toast } from "vant";
import { apiRoot } from "@/config";
import axios from "axios";
import isMobile from "ismobilejs";

// const ENV = process.env.NODE_ENV;

// 创建axios实例
const service = axios.create({
  baseURL: apiRoot, // 设置默认的BASE_API
  // baseURL: ENV === "development" ? "/api" : apiRoot, // 设置默认的BASE_API
  timeout: 60 * 1000 // 请求超时时间 60s
});

// request 请求发送之前 拦截器
service.interceptors.request.use(
  config => {
    // const { activity_id, uid } = $utils.getUrlParams()
    const auth = localStorage.getItem("auth") || "{}";
    const { token } = JSON.parse(auth);
    // config.params = {
    //   token,
    //   ...(config.params || {})
    // };
    config.data = {
      token,
      ...(config.data || {})
    };
    // console.log('params', config.params, 'url', $utils.getUrlParams())
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// request 请求收到后 拦截器设置
service.interceptors.response.use(
  response => {
    if (response) {
      if (response.data) {
        const code = response.data.code;
        // const msg = response.data.error_msg || response.data.msg

        if (code === 10401 || code === 10403) {
          localStorage.setItem("auto", "{}");
          // location.reload();
          return;
        }

        if (code !== 0) {
          // 如何status 返回false 表示出错
          return Promise.reject(response.data);
        }
      }
      return response.data;
    } else {
      isMobile().any
        ? Toast("响应成功，但是响应信息不存在！")
        : Message.error("响应成功，但是响应信息不存在！");
    }
  },
  error => {
    // Toast.fail(ENV === 'development' ? '服务器端产生错误！' : '网络因素，请稍后重试！')
    return Promise.reject(error.response.data); // 返回接口返回的错误信息
  }
);

function request({ showToast = true, ...args }) {
  return service(args).catch(error => {
    const { msg } = error;
    if (showToast) {
      isMobile().any
        ? Toast(msg ? msg : "服务出现了问题")
        : Message.error(msg ? msg : "服务出现了问题");
    }
    console.error(`${args.url} 接口报错 => ${msg || ""}`);
    return Promise.reject(error);
  });
}

export default request;
