import WebLayout from "@/views/web/Layout";
// web
// import Home from "../views/web/Home.vue";
const Home = () => import("../views/web/Home.vue");
const Search = () => import("../views/web/Search.vue");
const Detail = () => import("../views/web/Detail.vue");
const About = () => import("../views/web/About1.vue");
const Lecture = () => import("../views/web/Lecture.vue");
const LectureDetail = () => import("../views/web/LectureDetail.vue");
const Events = () => import("../views/web/Events.vue");
const EventDetail = () => import("../views/web/EventDetail.vue");
const Help = () => import("../views/web/Help.vue");
const Article = () => import("../views/web/Article.vue");
const PublishEvent = () => import("../views/web/PublishEvent.vue");

const User = () => import("../views/web/User/User.vue");
const Center = () => import("../views/web/User/Center.vue");
const Teams = () => import("../views/web/User/Teams.vue");
const MyPushu = () => import("../views/web/User/MyPushu.vue");
const MyPushuDetail = () => import("../views/web/User/MyPushuDetail.vue");
const MyEvents = () => import("../views/web/User/MyEvents.vue");
const UploadPushu = () => import("../views/web/User/Upload.vue");
const Watched = () => import("../views/web/User/Watched.vue");
const Benefit = () => import("../views/web/User/Benefit.vue");
const BenefitWithdraw = () => import("../views/web/User/BenefitWithdraw.vue");
const BenefitWithdrawLog = () =>
  import("../views/web/User/BenefitWithdrawLog.vue");

const webRoute = [
  {
    path: "/web",
    component: WebLayout,
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
        meta: {
          title: "首页"
        }
      },
      {
        path: "search",
        name: "Search",
        component: Search,
        meta: {
          title: "寻根述源"
        }
      },
      {
        path: "search/detail",
        name: "Detail",
        component: Detail,
        meta: {
          title: "寻根述源"
        }
      },
      {
        path: "article",
        name: "ArticleDetail",
        component: Article,
        meta: {
          title: "文章详情"
        }
      },
      {
        path: "lecture",
        name: "Lecture",
        component: Lecture,
        meta: {
          title: "述源讲堂"
        }
      },
      {
        path: "lecture/:id",
        name: "LectureDetail",
        component: LectureDetail,
        meta: {
          title: "述源讲堂"
        }
      },
      {
        path: "events",
        name: "Events",
        component: Events,
        meta: {
          title: "寻根动态"
        }
      },
      {
        path: "publish-event",
        name: "PublishEvent",
        component: PublishEvent,
        meta: {
          title: "发布寻根"
        }
      },
      {
        path: "events/:id",
        name: "EventDetail",
        component: EventDetail,
        meta: {
          title: "寻根动态"
        }
      },
      {
        path: "about",
        name: "About",
        component: About,
        meta: {
          title: "关于我们"
        }
      },
      {
        path: "help",
        name: "Help",
        component: Help,
        meta: {
          title: "帮助中心"
        }
      },
      {
        path: "user",
        // name: "User",
        component: User,
        meta: {
          title: "个人中心"
        },
        children: [
          {
            path: "center",
            name: "Center",
            component: Center,
            meta: {
              title: "个人中心"
            }
          },
          {
            path: "benefit/withdraw",
            name: "BenefitWithdraw",
            component: BenefitWithdraw,
            meta: {
              title: "申请提现"
            }
          },
          {
            path: "benefit/withdraw-log",
            name: "BenefitWithdrawLog",
            component: BenefitWithdrawLog,
            meta: {
              title: "提现记录"
            }
          },
          {
            path: "benefit",
            name: "Benefit",
            component: Benefit,
            meta: {
              title: "我的收益"
            }
          },
          {
            path: "teams",
            name: "Teams",
            component: Teams,
            meta: {
              title: "我的团队成员"
            }
          },
          {
            path: "my-pushu",
            name: "MyPushu",
            component: MyPushu,
            meta: {
              title: "我的谱书"
            }
          },
          {
            path: "my-events",
            name: "MyEvents",
            component: MyEvents,
            meta: {
              title: "我的发布"
            }
          },
          {
            path: "my-pushu/:id",
            name: "MyPushuDetail",
            component: MyPushuDetail,
            meta: {
              title: "我的谱书详情"
            }
          },
          {
            path: "upload",
            name: "UploadPushu",
            component: UploadPushu,
            meta: {
              title: "上传谱书"
            }
          },
          {
            path: "watched",
            name: "Watched",
            component: Watched,
            meta: {
              title: "已观看收费谱书"
            }
          },
          {
            path: "/",
            redirect: "/web/user/center"
          }
        ]
      },
      {
        path: "*",
        redirect: "home"
      }
    ]
  }
];

export default webRoute;
