export function createOAuthUrl(url = window.location.href, appid) {
  console.log("url", url);
  appid = appid || window.appid;
  let redirServiceUrl = `${window.redirHost}/redir.html`;

  const redirUrl = encodeURIComponent(
    `${redirServiceUrl}?url=${encodeURIComponent(url)}`
  );
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
}
