// import isMobile from "ismobilejs";

import H5Layout from "@/views/h5/Layout";

const Home = () => import("@/views/h5/Home.vue");
const Login = () => import("@/views/h5/Login.vue");
const Search = () => import("@/views/h5/Search.vue");
const SearchResult = () => import("@/views/h5/SearchResult.vue");
const Detail = () => import("@/views/h5/Detail.vue");
const LectureHome = () => import("@/views/h5/LectureHome.vue");
const Lecture = () => import("../views/h5/Lecture.vue");
const LectureDetail = () => import("../views/h5/LectureDetail.vue");
const Events = () => import("../views/h5/Events.vue");
const EventDetail = () => import("../views/h5/EventDetail.vue");
const PublishEvent = () => import("../views/h5/EventPublish.vue");
const Help = () => import("@/views/h5/Help.vue");
const Article = () => import("@/views/h5/Article.vue");

const Profile = () => import("@/views/h5/User/Profile.vue");
const UserCenter = () => import("@/views/h5/User/Center.vue");
const UserMyQrcode = () => import("@/views/h5/User/MyQrcode.vue");
const UserTeams = () => import("@/views/h5/User/Teams.vue");
const UserMyPushu = () => import("@/views/h5/User/MyPushu.vue");
const UserWatched = () => import("@/views/h5/User/Watched.vue");
const UserMyPushuDetail = () => import("@/views/h5/User/MyPushuDetail.vue");
const UserMyPushuPreview = () => import("@/views/h5/User/MyPushuPreview.vue");
const UserMyEvents = () => import("../views/h5/User/MyEvents.vue");
const Benefit = () => import("../views/h5/User/Benefit.vue");
const BenefitWithdraw = () => import("../views/h5/User/BenefitWithdraw.vue");
const BenefitWithdrawLog = () =>
  import("../views/h5/User/BenefitWithdrawLog.vue");

const mobileRoute = [
  {
    path: "/h5",
    component: H5Layout,
    children: [
      {
        name: "H5Home",
        path: "home",
        component: Home,
        meta: {
          title: "首页"
        }
      },
      {
        name: "H5Login",
        path: "login",
        component: Login,
        meta: {
          title: "登陆/注册"
        }
      },
      {
        name: "H5Search",
        path: "search",
        component: Search,
        meta: {
          title: "搜索"
        }
      },
      {
        name: "H5SearchResult",
        path: "search-result",
        component: SearchResult,
        meta: {
          title: "查谱"
        }
      },
      {
        name: "H5SearchDetail",
        path: "search/detail",
        component: Detail,
        meta: {
          title: "详情",
          hideTabbar: true
        }
      },
      {
        path: "article",
        name: "H5ArticleDetail",
        component: Article,
        meta: {
          title: "文章详情"
        }
      },
      {
        path: "lecture_home",
        name: "H5LectureHome",
        component: LectureHome,
        meta: {
          title: "学堂首页"
        }
      },
      {
        path: "lecture",
        name: "H5Lecture",
        component: Lecture,
        meta: {
          title: "课程列表"
        }
      },
      {
        path: "lecture/:id",
        name: "H5LectureDetail",
        component: LectureDetail,
        meta: {
          title: "课程详情",
          hideTabbar: true
        }
      },
      {
        path: "events",
        name: "H5Events",
        component: Events,
        meta: {
          title: "寻根"
        }
      },
      {
        path: "publish-event",
        name: "H5PublishEvents",
        component: PublishEvent,
        meta: {
          title: "发布寻根"
        }
      },
      {
        path: "events/:id",
        name: "H5EventDetail",
        component: EventDetail,
        meta: {
          title: "寻根"
        }
      },
      {
        path: "help",
        name: "H5Help",
        component: Help,
        meta: {
          title: "帮助中心"
        }
      },
      {
        name: "H5UserCenter",
        path: "user/center",
        component: UserCenter,
        meta: {
          title: "个人中心"
        }
      },
      {
        name: "H5UserProfile",
        path: "user/profile",
        component: Profile,
        meta: {
          title: "修改资料"
        }
      },
      {
        name: "H5UserTeams",
        path: "user/teams",
        component: UserTeams,
        meta: {
          title: "我的团队成员"
        }
      },
      {
        name: "H5MyPushu",
        path: "user/my-pushu",
        component: UserMyPushu,
        meta: {
          title: "我上传的谱书",
          hideTabbar: true
        }
      },
      {
        name: "H5MyPushuPreview",
        path: "user/my-pushu/:id/preview",
        component: UserMyPushuPreview,
        meta: {
          title: "预览谱书",
          hideTabbar: true
        }
      },
      {
        name: "H5MyPushuDetail",
        path: "user/my-pushu/:id",
        component: UserMyPushuDetail,
        meta: {
          title: "我的谱书",
          hideTabbar: true
        }
      },
      {
        name: "H5Watched",
        path: "user/watched",
        component: UserWatched,
        meta: {
          title: "已观看收费谱书",
          hideTabbar: true
        }
      },
      {
        name: "H5MyEvents",
        path: "user/my-events",
        component: UserMyEvents,
        meta: {
          title: "我的发布"
        }
      },
      {
        name: "H5QRcode",
        path: "user/my-qrcode",
        component: UserMyQrcode,
        meta: {
          title: "分享码",
          hideTabbar: true
        }
      },
      {
        path: "user/benefit/withdraw",
        name: "H5BenefitWithdraw",
        component: BenefitWithdraw,
        meta: {
          title: "申请提现"
        }
      },
      {
        path: "user/benefit/withdraw-log",
        name: "H5BenefitWithdrawLog",
        component: BenefitWithdrawLog,
        meta: {
          title: "提现记录"
        }
      },
      {
        path: "user/benefit",
        name: "H5Benefit",
        component: Benefit,
        meta: {
          title: "我的收益"
        }
      },
      {
        path: "user/",
        redirect: "user/center"
      },
      {
        path: "*",
        redirect: "/"
      }
    ]
  }
];

export default mobileRoute;
