/**
 * 事件发布/订阅 之所以不用Vue实例代替是因为Vue3中即将失效
 * @docs https://github.com/developit/mitt#api
 */

import mitt from "mitt";

const mittInstance = mitt();

// 兼容一下之前的写法
mittInstance.$on = mittInstance.on;
mittInstance.$emit = mittInstance.emit;

/**
 * 根据事件名删除全部handler
 * @param {string} type 事件名
 */
mittInstance.offType = type => {
  if (!type) return;
  mittInstance.all.delete(type);
};

mittInstance.on("*", e => {
  console.warn("handle event ==>", e);
});

export default mittInstance;
