<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="false"
    width="500px"
    custom-class="modal-container"
  >
    <div slot="title" class="title">
      <span class="info">
        {{ title }}
      </span>
      <img
        alt="icon"
        class="icon"
        src="@/assets/images/login-dialog/img_64028_0_0.png"
        @click="visible = false"
      />
    </div>
    <div class="login-modal">
      <template v-if="current !== 'forgotPassword'">
        <div id="tabs" class="tabs">
          <span
            id="tab-login"
            class="tab"
            :class="{ active: current === 'login' }"
            @click="current = 'login'"
          >
            登录
          </span>
          <span
            id="tab-register"
            class="tab"
            :class="{ active: current === 'register' }"
            @click="current = 'register'"
          >
            注册
          </span>
        </div>
        <div
          class="underline"
          :class="[current === 'register' ? 'right' : 'left']"
        ></div>
      </template>
      <div v-if="current === 'login'" class="content">
        <input
          v-model="loginForm.mobile"
          class="text-input"
          placeholder="请输入手机号"
        />
        <input
          v-model="loginForm.password"
          type="password"
          class="text-input"
          placeholder="请输入密码"
        />
        <span class="forget-password" @click="current = 'forgotPassword'">
          忘记密码？
        </span>
        <div class="ft" @click="submit('login')">
          登录
        </div>
      </div>
      <div v-if="current === 'register'" class="content">
        <input
          v-model="registerForm.mobile"
          class="text-input"
          placeholder="请输入手机号"
        />
        <div class="input-wrapper">
          <input
            v-model="registerForm.sms_code"
            class="text-input"
            placeholder="请输入验证码"
          />
          <el-button
            class="get-verify-code"
            :disabled="smsLoading"
            @click="sendSms('register')"
            >{{ smsLoading ? smsCount : "获取验证码" }}</el-button
          >
        </div>
        <input
          v-model="registerForm.password"
          type="password"
          class="text-input"
          placeholder="请输入密码"
        />
        <input
          v-model="registerForm.twopassword"
          type="password"
          class="text-input"
          placeholder="请再次输入密码"
        />
        <div class="input-wrapper">
          <input
            v-model="registerForm.real_name"
            class="text-input"
            placeholder="请输入姓名"
          />
          <el-select
            v-model="registerForm.sex"
            class="select-sex"
            placeholder="请选择"
          >
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
          </el-select>
        </div>
        <div class="ft" @click="submit('register')">
          注册
        </div>
      </div>
      <div v-if="current === 'forgotPassword'" class="content">
        <input
          v-model="forgotPasswordForm.mobile"
          class="text-input"
          placeholder="请输入手机号"
        />
        <div class="input-wrapper">
          <input
            v-model="forgotPasswordForm.sms_code"
            class="text-input"
            placeholder="请输入验证码"
          />
          <el-button
            class="get-verify-code"
            :disabled="smsLoading"
            @click="sendSms('forgotPassword')"
            >{{ smsLoading ? smsCount : "获取验证码" }}</el-button
          >
        </div>
        <input
          v-model="forgotPasswordForm.password"
          type="password"
          class="text-input"
          placeholder="请输入新密码"
        />
        <input
          v-model="forgotPasswordForm.password2"
          type="password"
          class="text-input"
          placeholder="请再次输入密码"
        />
        <div class="ft" @click="submit('forgotPassword')">
          完成
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { Dialog, Button, Select, Option } from "element-ui";
import apis from "@/apis/index";

export default {
  name: "LoginDialog",
  components: {
    "el-dialog": Dialog,
    "el-button": Button,
    "el-select": Select,
    "el-option": Option
  },
  data() {
    return {
      visible: false,
      current: "login",
      smsLoading: false,
      smsCount: 60,
      destTimestamp: 0,
      loginForm: {
        mobile: "",
        password: ""
      },
      registerForm: {
        mobile: "",
        sms_code: "",
        password: "",
        twopassword: "",
        real_name: "",
        sex: 1
      },
      forgotPasswordForm: {
        mobile: "",
        sms_code: "",
        password: "",
        password2: ""
      }
    };
  },
  computed: {
    title() {
      return this.current === "forgotPassword"
        ? "忘记密码"
        : "宗亲用户登录/注册";
    }
  },
  created() {
    this.unlisten = this.initEvents();
  },
  beforeDestroy() {
    this.unlisten && this.unlisten();
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    initEvents() {
      const loginListener = () => {
        this.current = "login";
        this.loginForm = {
          mobile: "",
          password: ""
        };
        this.visible = true;
      };

      const registerListener = () => {
        this.current = "register";
        this.registerForm = {
          mobile: "",
          sms_code: "",
          password: "",
          twopassword: "",
          real_name: "",
          sex: 1
        };
        this.visible = true;
      };

      this.bus.on("login", loginListener);
      this.bus.on("register", registerListener);

      return () => {
        this.bus.off("login", loginListener);
        this.bus.off("login", registerListener);
      };
    },

    async sendSms(type) {
      if (this.smsLoading) return;
      let mobile = "";
      switch (type) {
        case "register":
          if (!this.registerForm.mobile.trim()) return;
          mobile = this.registerForm.mobile.trim();
          break;
        case "forgotPassword":
          if (!this.forgotPasswordForm.mobile.trim()) return;
          mobile = this.forgotPasswordForm.mobile.trim();
          break;
        default:
          break;
      }
      await apis.smsCode({ mobile: parseInt(mobile) });
      this.$message.success("验证码发送成功");
      this.smsLoading = true;
      this.destTimestamp = Date.now() + 1000 * 60;
      this.smsCounter = setInterval(() => {
        if (Date.now() >= this.destTimestamp) {
          this.smsCounter && clearInterval(this.smsCounter);
          this.smsLoading = false;
          this.smsCount = 60;
          return;
        }
        this.smsCount = Math.round((this.destTimestamp - Date.now()) / 1000);
      }, 1000);
    },

    async submit(type) {
      switch (type) {
        case "login":
          {
            const { data } = await apis.login(this.loginForm);
            if (data.token) {
              localStorage.setItem(
                "auth",
                JSON.stringify({ token: data.token, uid: data.uid })
              );
              this.fetchUserInfo();
              this.$message.success("登陆成功");
              this.bus.emit("login-success");
              this.visible = false;
            }
          }
          break;
        case "register":
          {
            const { data } = await apis.register({
              ...this.registerForm,
              ...this.$route.query
            });
            console.log(data);
            if (data.token) {
              localStorage.setItem(
                "auth",
                JSON.stringify({ token: data.token, uid: data.uid })
              );
              this.fetchUserInfo();
              this.$message.success("注册成功");
              this.bus.emit("login-success");
              this.visible = false;
            }
          }
          break;
        case "forgotPassword":
          {
            const { data } = await apis.fotgotPassword({
              ...this.forgotPasswordForm
            });
            if (data) {
              this.$message.success("密码找回成功");
              this.current = "login";
            }
          }
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.modal-container {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
  align-items: center;
  background-color: #fbe1c3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 21px;
  padding-right: 20px;
  position: relative;
  width: 500px;

  .info {
    color: #CA0A15;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    font-weight: bold;
  }
  .icon {
    height: 10px;
    width: 10px;
    cursor: pointer;
  }
}
.login-modal {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 500px;

  .tabs {
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: space-between;
    margin-top: 30px;
    position: relative;
    width: 183px;
  }
  .tab {
    color: #8c6b64;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;

    &.active {
      color: #CA0A15;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .underline {
    background-color: #CA0A15;
    height: 4px;
    margin-top: 14px;
    overflow: hidden;
    position: relative;
    width: 20px;
    transition: margin-left 0.3s ease;

    &.left {
      margin-left: 168px;
    }

    &.right {
      margin-left: 310px;
    }
  }
  .content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-bottom: 32px;
    width: 100%;
  }
  .text-input {
    align-items: center;
    align-self: center;
    border-color: #efefef;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    width: 460px;
    color: #666666;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    text-decoration: none;
    padding: 14px 20px;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #CA0A15;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
    }
  }
  .input-wrapper {
    position: relative;
    width: 460px;
    align-self: center;

    .get-verify-code {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      border-radius: 4px;
      background-color: #CA0A15;
      padding-right: 20px;
      padding-left: 20px;
      height: 30px;
      overflow: hidden;
      width: 100px;
      color: #ffffff;
      font-size: 12px;
      position: absolute;
      right: 5px;
      bottom: 5px;
      // cursor: pointer;

      &:disabled {
        opacity: 0.7;
      }
    }

    .select-sex {
      height: 40px;
      width: 100px;
      font-size: 12px;
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }
  .forget-password {
    -webkit-line-clamp: 2;
    align-self: flex-end;
    color: #0ab0fb;
    font-size: 12px;
    height: 13px;
    letter-spacing: 0;
    line-height: 13px;
    margin-right: 20px;
    margin-top: 14px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    width: 64px;
    cursor: pointer;
  }
  .ft {
    align-items: center;
    align-self: center;
    background-color: #CA0A15;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-top: 13px;
    overflow: hidden;
    padding-left: 85px;
    padding-right: 83px;
    position: relative;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>
