import Vue from "vue";
import Vuex from "vuex";
import apis from "@/apis";

import sex1Img from "@/assets/h5images/user/sex1.png";
import sex2Img from "@/assets/h5images/user/sex2.png";
import avatar1Img from "@/assets/avatar_1.png";
import avatar2Img from "@/assets/avatar_2.png";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {
      sexImg: sex1Img,
      avatarImg: avatar1Img,
      isLogin: !!localStorage.getItem("auth"),
      openid: localStorage.getItem("shuyuan_openid")
    },
    vipIndex: []
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setVipIndex(state, payload) {
      state.vipIndex = payload;
    }
  },
  actions: {
    async fetchUserInfo({ commit, dispatch, state }) {
      const { uid, token } = JSON.parse(localStorage.getItem("auth") || "{}");
      if (!uid || !token) return;
      const {
        data: { user_info }
      } = await apis.userInfo({ uid, token });

      const {
        data: { user_ewm, user_url }
      } = await apis.userQR();

      commit("setUserInfo", {
        ...state.userInfo,
        ...user_info,
        isLogin: true,
        qrcode: user_ewm,
        inviteUrl: user_url,
        sexImg: user_info.sex === 1 ? sex1Img : sex2Img,
        avatarImg: user_info.sex === 1 ? avatar1Img : avatar2Img
      });

      dispatch("fetchVipIndex");
    },
    async fetchOpenId({ commit, state }, { code }) {
      // const { token } = JSON.parse(localStorage.getItem("auth") || "{}");
      if (!code) return;
      const {
        data: { ukey }
      } = await apis.code2openid({ code });

      // Toast(ukey);
      console.log(ukey);

      localStorage.setItem("shuyuan_openid", ukey);

      commit("setUserInfo", {
        ...state.userInfo,
        openid: ukey
      });
    },
    async fetchVipIndex({ commit }) {
      const { uid, token } = JSON.parse(localStorage.getItem("auth") || "{}");
      if (!uid || !token) return;
      const {
        data: { list }
      } = await apis.rechargeIndex({ uid, token });

      console.log(list);
      commit("setVipIndex", list);
    },
    clearUserInfo({ commit }) {
      commit("setUserInfo", { isLogin: false, openid: "" });
      localStorage.removeItem("auth");
    }
  },
  modules: {}
});
