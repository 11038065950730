import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";
import isMobile from "ismobilejs";

import webRoute from "./webRoute";
import h5Route from "./h5Route";

//h5

// const Home =

let { token } = JSON.parse(localStorage.getItem("auth") || "{}");

const BASE_TITLE = document.title;

Vue.use(VueRouter);

const setDocumentTitle = function(title) {
  document.title = title;
  let ua = navigator.userAgent;
  if (/\bMicroMessenger\/([\d.]+)/.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    var i = document.createElement("iframe");
    i.src = "/favicon.png";
    i.style.display = "none";
    i.onload = function() {
      setTimeout(function() {
        i.remove();
      }, 9);
    };
    document.body.appendChild(i);
  }
};

const routes = [
  ...h5Route,
  ...webRoute,
  {
    path: "/web/",
    redirect: to => {
      console.log(to);
      return isMobile() ? "/h5/home" : "/web/home";
    }
  },
  {
    path: "/",
    redirect: to => {
      console.log(to);
      return isMobile() ? "/h5/home" : "/web/home";
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _, next) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("auth") || "{}").token;
  }
  if (token && !store.state.userInfo.isLogin) {
    store.commit("setUserInfo", {
      ...store.state.userInfo,
      isLogin: true
    });
  }
  if (!isMobile().any && to.path.includes("h5")) {
    next(to.fullPath.replace("h5", "web"));
    return;
  }

  if (isMobile().any && to.path.includes("web")) {
    next(to.fullPath.replace("web", "h5"));
  }

  if (!isMobile().any) {
    // 电脑端
    if (!token && to.path.includes("user")) {
      next("/web/home");
      return;
    }
  }

  if (isMobile().any) {
    if (!token && to.path.includes("user")) {
      next("/h5/login");
      return;
    }
  }

  next();
});

router.afterEach(routes => {
  typeof routes.meta.title !== undefined &&
    setDocumentTitle(
      routes.meta.title ? `${routes.meta.title} - ${BASE_TITLE}` : BASE_TITLE
    );
});

export default router;
