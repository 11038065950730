import request from "@/utils/request";

class Apis {
  /**
   * 注册
   */
  register(data) {
    return request({
      method: "POST",
      url: "user/register",
      data,
    });
  }

  smsCode(data) {
    return request({
      method: "POST",
      url: "user/sms_code",
      data,
    });
  }

  login(data) {
    return request({
      method: "POST",
      url: "user/login",
      data,
    });
  }

  fotgotPassword(data) {
    return request({
      method: "POST",
      url: "user/getpassword",
      data,
    });
  }

  updateProfile(data) {
    return request({
      method: "POST",
      url: "user/update_user_info",
      data,
    });
  }

  uploadAvatar(data) {
    return request({
      method: "POST",
      url: "user/upload_avatar",
      data,
    });
  }

  userInfo(data) {
    return request({
      method: "POST",
      url: "user/info",
      data,
    });
  }

  userTeams(data) {
    return request({
      method: "POST",
      url: "user/childslist",
      data,
    });
  }

  secondUserTeams(data) {
    return request({
      method: "POST",
      url: "user/secchildslist",
      data,
    });
  }

  rechargeIndex(data) {
    return request({
      method: "POST",
      url: "recharge_set/index",
      data,
    });
  }

  userIncome(data) {
    return request({
      method: "POST",
      url: "user_income/index",
      data,
    });
  }

  userWithdraw(data) {
    return request({
      method: "POST",
      url: "user_withdraw/add",
      data,
    });
  }

  userWithdrawIndex(data) {
    return request({
      method: "POST",
      url: "user_withdraw/index",
      data,
    });
  }

  recharge(data) {
    return request({
      method: "POST",
      url: "recharge_set/chongzhi",
      data,
    });
  }

  rechargeBook(data) {
    return request({
      method: "POST",
      url: "recharge_set/shop",
      data,
    });
  }

  userQR(data) {
    return request({
      method: "POST",
      url: "user/userewm",
      data,
    });
  }

  code2openid(data) {
    return request({
      method: "POST",
      url: "user/login_wx",
      data,
    });
  }

  orderDetail(data) {
    return request({
      method: "POST",
      url: "order/detail",
      data,
    });
  }

  indexGroup(params) {
    return request({
      method: "GET",
      url: "index_article/index_group",
      params,
    });
  }

  indexList(params) {
    return request({
      method: "GET",
      url: "index_article/index",
      params,
    });
  }

  indexArticleCate(params) {
    return request({
      method: "GET",
      url: "index_article_cat/detail",
      params,
    });
  }

  indexDetail(params) {
    return request({
      method: "GET",
      url: "index_article/detail",
      params,
    });
  }
}

export default new Apis();
